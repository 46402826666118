import React, { useEffect, useCallback } from "react"
import { useSnackbar } from "notistack"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

// @ts-ignore
import { swKey, swCallbackKey } from "../../conf/conf"

export default () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const refresh = useCallback(() => {
    window.location.reload()
  }, [])
  const closeNoti = useCallback(() => {
    closeSnackbar(swKey)
  }, [])

  useEffect(() => {
    ;(window as any)[swCallbackKey] = () => {
      enqueueSnackbar(`当前页面发布了更新的版本，是否刷新获取更好的体验?`, {
        key: swKey,
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        variant: "info",
        action: (
          <>
            <Button color="secondary" size="small" onClick={refresh}>
              刷新
            </Button>
            <IconButton size="small" color="inherit" onClick={closeNoti}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ),
      })
    }
    if ((window as any)[swKey]) {
      ;(window as any)[swCallbackKey]()
    }
  }, [])

  return <span />
}
