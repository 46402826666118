//
// ==========================================================================
//                          restapi 相关的接口
// ==========================================================================
//

export const LC_KEYS_REST_HEADER = {
  "X-LC-Id": "lAmGU3KrKL17OlruaSSK6iqN-gzGzoHsz",
  "X-LC-Key": "tYnf8wUGQBVnhergFmzvIvuC", // readonly
  "Content-Type": "application/json",
}
export async function fetchRestApi(api: string) {
  const r = await fetch(api, {
    method: "get",
    headers: LC_KEYS_REST_HEADER,
    mode: "cors",
  })
  return await r.json()
}

export async function updateOrCreateRestApi(
  api: string,
  objectId: string,
  body: BodyInit
) {
  const r = await fetch(`${api}${objectId ? "/" + objectId : ""}`, {
    method: objectId ? "put" : "post",
    headers: LC_KEYS_REST_HEADER,
    body,
    mode: "cors",
  })
  return await r.json()
}

const blog_counterUrl = "https://api-leancloud.ubug.io/1.1/classes/blog_counter"
type CollectType = "views" | "comments"
export async function collectView(attr: CollectType = "views") {
  if (/localhost|127.0.0.1/.test(window.location.host)) return;
  const pathName = window.location.pathname.replace(/\/$/, "")
  const response = await fetchRestApi(
    `${blog_counterUrl}?where=${JSON.stringify({
      blog: pathName,
    })}`
  )
  let objectId
  let num = 1
  if (response.results && response.results.length !== 0) {
    objectId = response.results[0].objectId
    num = (response.results[0][attr] || 0) + 1
  }
  const res = await updateOrCreateRestApi(
    blog_counterUrl,
    objectId,
    JSON.stringify({
      [attr]: num,
      blog: pathName,
    })
  )
  console.log(`--> ${pathName} 已统计`, res)
  return res
}
